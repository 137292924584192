const { default: Mercury } = require('@/service/base/mercury');

class MerchantMercuryRequest extends Mercury {
    getAll(groupId) {
        return this.fetch({
            url: '/register/merchant',
            params: {
                group_id: groupId,
            },
        });
    }
}

export default new MerchantMercuryRequest();
