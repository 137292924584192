<template>
  <b-row :key="componentKey">
    <b-col md="4">
      <b-card>
        <h5> Estabelecimentos </h5>
        <div v-if="data.merchants && data.merchants.length">
          <div
            v-for="(item, index) in data.merchants"
            :key="index"
          >
            <b-row
              v-if="merchantsMap.get(item)"
              class="px-1 justify-content-between align-items-center"
            >
              <p class="m-0">
                {{ merchantsMap.get(item).name }}
              </p>

              <b-button
                v-if="!update"
                class="clickable remove"
                variant="transparent"
                @click="removeMerchant(merchantsMap.get(item))"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                />
              </b-button>

            </b-row>
          </div>
        </div>

        <div v-else>
          <p> Não possui estabeciementos selecionados</p>
        </div>
      </b-card>
    </b-col>
    <b-col md="8">
      <b-card>
        <b-form>
          <b-form-group
            label-for="name"
            label="Nome"
          >
            <b-form-input
              id="name"
              v-model="data.name"
              placeholder="Nome"
            />
          </b-form-group>

          <b-form-group
            label-for="merchants"
            label="Estabelecimentos"
          >
            <b-input-group>

              <template #append>
                <b-button
                  variant="primary"
                  :disabled="!merchantSelected"
                  @click="addMerchant"
                >
                  Adicionar
                </b-button>
              </template>

              <BFormSelect
                id="merchants"
                v-model="merchantSelected"
                :disabled="update"
                placeholder="Nome"
                :options="merchantsOptions"
              />

            </b-input-group>

          </b-form-group>

          <slot name="append-form" />
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
    BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton, BFormSelect, BInputGroup,
} from 'bootstrap-vue';

export default {
    components: {
        BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton, BFormSelect, BInputGroup,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },

        merchants: {
            type: Array,
            default: () => [],
        },

        update: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            merchantSelected: null,
            componentKey: 1,
            merchantsOptionsData: JSON.parse(JSON.stringify(this.merchants)),
            merchantsMap: new Map(),
        };
    },

    computed: {
        merchantsOptions() {
            const options = this.merchantsOptionsData.map(item => ({
                value: item.id,
                text: `${item.name} - ${item.veripag_id}`,
            })).sort((a, b) => a.text.localeCompare(b.text));

            options.unshift({
                value: null,
                text: 'Selecione um estabelecimento',
            });

            return options;
        },
    },

    watch: {
        merchants() {
            this.merchants.forEach(merchant => {
                this.merchantsMap.set(merchant.id, merchant);
            });

            this.componentKey++;
        },
    },

    methods: {
        addMerchant() {
            this.data.merchants = this.data.merchants || [];
            this.data.merchants.push(this.merchantSelected);
            const index = this.merchantsOptionsData.findIndex(item => item.id === this.merchantSelected);
            this.merchantSelected = null;
            this.merchantsOptionsData.splice(index, 1);
        },

        removeMerchant(data) {
            const index = this.data.merchants.findIndex(item => item.id === data.id);
            this.data.merchants.splice(index, 1);
            this.merchantsOptionsData.push(data);
        },
    },
};
</script>

<style>
  .remove:hover * {
    cursor: pointer;
    color:#ea5455;
  }
</style>
